import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import ResultBlock from "../components/content/result/resultBlock"
import DashboardBlock from "../components/content/dashboard/dashboard"

const ResultPage = () => {
  const { type } = useParams()

  return (
    <>
      <Helmet>
        <meta name="description" content="Посмотрите результаты анализа от нашей нейросети" />
        <title>Результаты</title>
      </Helmet>
      <main>
        <div className="dashboard__wrapper">
          <DashboardBlock type={type} />
          <ResultBlock type={type} />
        </div>
      </main>
    </>
  )
}

export default ResultPage