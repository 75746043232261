import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import Spinner from "../../spinner/spinner";
import ErrorScreen from "../../../error/error";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title);

const ResultProfile = ({ resultData, error, loading, errorText }) => {
  const { type } = useParams();
  const [hasData, setHasData] = useState(false);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    if (resultData) {
      setHasData(true);
      setProfileData(resultData.neuro_result.features);
    } else {
      setHasData(false);
    }
  }, [resultData]);

  const labels = profileData ? Object.keys(profileData) : [];
  const values = profileData ? Object.values(profileData) : [];

  const weakValues = values.map(value => (value <= 10 && value >= 7 ? value : 0));
  const mediumValues = values.map(value => (value < 7 && value >= 4 ? value : 0));
  const strongValues = values.map(value => (value < 4 && value >= 1 ? value : 0));
  const minimumValues = values.map(value => (value === 0 ? 1 : 0));
  

  const correctedWeakValues = strongValues.map((val, index) => val + minimumValues[index]);
  
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Отлично",
        data: correctedWeakValues,
        backgroundColor: "#00995177",
        borderColor: "#00995177",
        borderWidth: 1,
      },
      {
        label: "В норме",
        data: mediumValues,
        backgroundColor: "#E5A00077",
        borderColor: "#E5A00077",
        borderWidth: 1,
      },
      {
        label: "Плохо",
        data: weakValues,
        backgroundColor: "#C00F0C77",
        borderColor: "#C00F0C77",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      title: {
        display: true,
        text: "Описание параметра доступно при клкие на полосу",
        font: {
          size: 16,
          weight: "bold",
        },
        padding: { bottom: 10 },
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: window.innerWidth < 768 ? 12 : 14,
            weight: "normal",
          },
        },
      },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Показатель",
          font: {
            size: window.innerWidth < 768 ? 12 : 14,
            weight: "bold",
          },
        },
        ticks: {
          font: {
            size: window.innerWidth < 768 ? 12 : 14,
            weight: "normal",
          },
        },
      },
      y: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            let maxLength = 15;
            if (value.length > maxLength) {
              return value.match(new RegExp('.{1,' + maxLength + '}', 'g'));
            }
            return value;
          },
          font: {
            size: 12,
            weight: "bold",
          },
        },
      },
    },
  };

  const Loading = loading ? <Spinner /> : null;
  const Error = error ? (
    <ErrorScreen
      errorText={errorText}
      errorLink={`${type}/result`}
      errorLinkText="Перезагрузить страницу"
    />
  ) : null;

  const content = hasData ? (
    <Bar data={chartData} options={chartOptions} />
  ) : null;

  return (
    <div className="result__profile">
      <div className="result__profile-text">
        <h3>Профиль кожи</h3>
        <p>Сильные и слабые стороны вашей кожи. Чем выше показатель, тем больше внимания стоит уделить этому признаку в уходе.</p>
      </div>
      <div className="result__diag">
        {Loading}
        {Error}
        {content}
      </div>
    </div>
  );
};

export default ResultProfile;
