import { useEffect } from "react"
import ReactDOM from "react-dom";
import SvgIcon from "../../../utils/svgIcon";

const Modal = ({ isOpen, onClose = () => { }, title, children }) => {

  useEffect(() => {

    if (isOpen) {
      document.body.style.overflow = "hidden";

      document.body.style.overflow = "";
    }
  }, [isOpen, onClose]);

  const handleEscClose = (event) => {
    if (event.key === "Escape" && typeof onClose === "function") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscClose);
    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [onClose]);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget && typeof onClose === "function") {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal__overlay" onClick={handleOverlayClick}>
      <div className="modal">
        {title && <h2 className="modal__title">{title}</h2>}
        <div className="modal__content">
          <button className="modal__close" onClick={onClose}>
            <SvgIcon id="close" width={16} height={16} />
          </button>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export const openModal = (setterFunction, modalName) => {
  setterFunction(modalName);
};

export const closeModal = (setterFunction) => {
  setterFunction(null);
};

export default Modal