import gif from "./error.gif";

const ErrorScreen = ({ errorText, errorLink, errorLinkText }) => {
  const handleReload = () => {
    window.location.href = errorLink || "/";
  };

  return (
    <div className="error">
      <img src={gif} alt="Ошибка" />
      <div className="error__content">
        <h2>Произошла ошибка</h2>
        <span>
          {errorText || "Ведутся работы или сервер временно недоступен"}
        </span>
        <p>Попробуйте повторить действие позднее</p>
        <button className="btn btn--blue" onClick={handleReload}>
          {errorLinkText || "На главную"}
        </button>
      </div>
    </div>
  );
}

export default ErrorScreen;