import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom";
import DashboardBlock from "../components/content/dashboard/dashboard";
import SurveyBlock from "../components/content/survey/survey";

const SurveyPage = () => {
  const { type } = useParams();

  return (
    <>
      <Helmet>
        <meta name="description" content="Завершите анкету для уточнения деталей анализа" />
        <title>Анкетирование</title>
      </Helmet>
      <main>
        <div className="dashboard__wrapper">
          <DashboardBlock type={type} />
          <SurveyBlock type={type} />
        </div>
      </main>
    </>
  )
}

export default SurveyPage