import { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import SvgIcon from "../../../utils/svgIcon";
import useSdService from "../../../services/scandermService";
import Spinner from "../spinner/spinner";
import ErrorScreen from "../../error/error";
import CircularProgress from "./components/CircularProgress";
import SurveyQuestion from "./components/SurveyQuestion";
import { useProgress } from "../../../providers/ProgressContext";

const SurveyBlock = () => {
  const [questions, setQuestions] = useState(null);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [answers, setAnswers] = useState([]);
  const { getSurvey, sendSurvey, loading, error, errorText } = useSdService();
  const { type } = useParams();
  const [loadError, setLoadError] = useState(false)
  const [testType, setTestType] = useState("simple")

  const { updateProgress } = useProgress();
  const navigate = useNavigate()

  useEffect(() => {
    setAnswers([]);
    const fetchQuestions = async () => {
      const res = await getSurvey(type, testType);
      if (res?.data?.data?.questions) {
        const loadedQuestions = res.data.data.questions;
        const startId = res.data.data.start_quetion;
        setQuestions(loadedQuestions);
        setVisitedSteps([startId]);
      }
    };
    fetchQuestions();
  }, []);

  const handleAnswer = (questionId, answerId) => {
    setAnswers(prev => {
      const existing = prev.find(ans => ans.question_id === questionId);
      if (existing) {
        return prev.map(ans =>
          ans.question_id === questionId ? { ...ans, answer_id: answerId } : ans
        );
      } else {
        return [...prev, { question_id: questionId, answer_id: answerId }];
      }
    });
  };

  const handleRadioSelect = (questionId, answerId) => {
    handleAnswer(questionId, answerId);
  };

  const handleCheckboxChange = (questionId, answerId, isChecked) => {
    setAnswers(prev => {
      const existing = prev.find(ans => ans.question_id === questionId);
      if (existing) {
        const updatedAnswers = isChecked
          ? [...existing.answer_id, answerId]
          : existing.answer_id.filter(id => id !== answerId);
        return prev.map(ans =>
          ans.question_id === questionId
            ? { ...ans, answer_id: updatedAnswers }
            : ans
        );
      }
      return [...prev, { question_id: questionId, answer_id: isChecked ? [answerId] : [] }];
    });
  };

  const handleBack = () => {
    setVisitedSteps(prev => {
      if (prev.length <= 1) return prev;
      return prev.slice(0, -1);
    });
  };

  const currentStep = visitedSteps[visitedSteps.length - 1];
  const currentQuestion = questions?.find(q => q.question_id === currentStep);
  const currentAnswer = answers.find(ans => ans.question_id === currentQuestion?.question_id);

  const isCurrentQuestionAnswered = (() => {
    if (!currentQuestion) return false;
    switch (currentQuestion.type) {
      case "radio":
        return !!currentAnswer && !!currentAnswer.answer_id;
      case "checkbox":
        return !!currentAnswer && Array.isArray(currentAnswer.answer_id) && currentAnswer.answer_id.length > 0;
      case "number":
        return !!currentAnswer && String(currentAnswer.answer_id).trim() !== "";
      default:
        return false;
    }
  })();

  const isLastQuestion = questions && currentStep === questions[questions.length - 1]?.question_id;
  const mainButtonText = isLastQuestion ? "Отправить" : "Далее";

  const handleMainButtonClick = () => {
    if (!currentQuestion) return;
    if (isLastQuestion) {
      handleSendSurvey();
      return;
    }
    let nextQ;
    switch (currentQuestion.type) {
      case "radio":
        nextQ =
          currentQuestion.answers.find(a => a.answer_id === currentAnswer?.answer_id)?.next_question ||
          currentQuestion.answers[0]?.next_question;
        break;
      case "checkbox":
      case "number":
        nextQ = currentQuestion.answers[0]?.next_question;
        break;
      default:
        return;
    }
    if (nextQ) {
      setVisitedSteps(prev => [...prev, nextQ]);
    }
  };

  const currentIndex = visitedSteps.length;
  const totalQuestions = questions?.length || 0;

  const handleSendSurvey = async () => {
    try {
      const res = await sendSurvey(type, answers, testType);
      if (res) {
        updateProgress(type, { testCompleted: true });
        navigate(`/result/${type}`)
      }
    } catch (e) {
      setLoadError(true)
    }
  };

  const getSurveyContent = () => {
    switch (true) {
      case loading:
        return <Spinner />;

      case loadError:
        return (
          <ErrorScreen
            errorText={errorText}
            errorLink={`/survey/${type}`}
            errorLinkText="Попробовать заново"
          />
        );

      case error:
        return (
          <ErrorScreen
            errorText={errorText}
            errorLink={`/survey/${type}`}
          />
        );

      default:
        return (
          <>
            <SurveyQuestion
              question={currentQuestion}
              currentAnswer={currentAnswer}
              currentStep={currentStep}
              onRadioSelect={handleRadioSelect}
              onCheckboxChange={handleCheckboxChange}
              onAnswerChange={handleAnswer}
            />
            <div className="survey__navigation">
              {visitedSteps.length > 1 && (
                <button className="btn btn--white" onClick={handleBack}>
                  Назад
                </button>
              )}
              <button
                className="btn"
                onClick={handleMainButtonClick}
                disabled={!isCurrentQuestionAnswered}
              >
                {mainButtonText}
              </button>
            </div>
          </>
        );
    }
  };

  return (
    <section className="survey">
      <div className="container">
        <div className="survey__container">
          <div className="survey__top">
            <NavLink to={`/photo/${type}`}>
              <SvgIcon id="bird" width={12} height={16} />
              Изменить фото
            </NavLink>
            <div className="survey__progress">
              {totalQuestions > 0 && <CircularProgress current={currentIndex} total={totalQuestions} />}
            </div>
          </div>
          <div className="survey__question">
            {getSurveyContent()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SurveyBlock;
