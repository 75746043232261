import { useHttp } from "../hooks/httpHook";

const useSdService = () => {
  const { loading, error, errorText, request, clearError } = useHttp();
  const _apiBase = "https://back.prestable.beautyscan.ru";

  // new test
  const startNewTest = async (type) => {
    const res = await request(`${_apiBase}/${type}/start_new_test/`, "POST");
    return res?.data?.result;
  };

  // photo upload
  const photoUpload = async (type, photo) => {
    const res = await request(`${_apiBase}/${type}/photo_upload/`, "POST", {
      photo: photo
    })
    return res?.data?.result;
  }

  // get test questions
  const getSurvey = async (type, testType) => {
    const res = await request(`${_apiBase}/${type}/test/?type=${testType}`)
    return res
  }

  // send test answers
  const sendSurvey = async (type, data, testType) => {
    const res = await request(`${_apiBase}/${type}/test/?type=${testType}`, "POST", {
      "data": data
    })
    return res?.data?.result
  }

  // results
  const getResults = async (type) => {
    const res = await request(`${_apiBase}/${type}/test_result`)
    return res?.data
  }

  const _transformResultStatus = (data) => {
    return {
      skinAge: data?.test_result?.skin_age || "Нет данных",
      faceRank: Math.round(data?.test_result?.face_rank) || "Нет данных",
      normRange: {
        name: data?.test_result?.norm_range?.name || "Нет данных",
        recomendation: data?.test_result?.norm_range?.recomendation || "Нет рекомендаций",
        color: data?.test_result?.norm_range?.color || "default",
        comment: data?.test_result?.norm_range?.comment || "Нет данных по коже"
      },
    }
  }

  return {
    loading,
    error,
    errorText,
    clearError,
    startNewTest,
    photoUpload,
    getSurvey,
    sendSurvey,
    getResults,
    _transformResultStatus,
  }
}

export default useSdService