import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import SvgIcon from "../../../utils/svgIcon";
import ResultsStatus from "./components/resultStatus";
import ResultParams from "./components/resultParams";
import useSdService from "../../../services/scandermService";
import { useProgress } from "../../../providers/ProgressContext";
import ResultProfile from "./components/resultProfile";

// 3rd block on page

const ResultBlock = () => {
  const { type } = useParams();
  const [resultData, setResultData] = useState(null);
  const [recsShowed, setRecsShowed] = useState(false);
  const { getResults, error, loading, errorText } = useSdService();
  const { updateProgress } = useProgress();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getResults(type);
        if (res.result) {
          setResultData(res.data);
        }
      } catch (e) {
        console.error("Ошибка загрузки данных:", e);
      }
    };

    fetchData();

    const timeout = setTimeout(() => {
      updateProgress(type, { resultsViewed: true });
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <section className="result">
      <div className="container">
        <div className="result__container">
          <div className="result__top">
            <NavLink to={`/survey/${type}`}>
              <SvgIcon id="bird" width={12} height={16} />
              К анкете
            </NavLink>
            <h2>Результаты</h2>
          </div>
          <div className="result__content">
            <ResultsStatus resultData={resultData} error={error} errorText={errorText} loading={loading} />
            <ResultParams resultData={resultData} error={error} errorText={errorText} loading={loading} />
            <ResultProfile resultData={resultData} error={error} errorText={errorText} loading={loading} />
          </div>
          <NavLink className="result__recs-btn btn" to={`${type}/recs`}>
            Перейти к рекомендациям
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default ResultBlock;
