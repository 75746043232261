import { ProgressProvider } from "./ProgressContext";
import { TokenProvider } from "./tokenProvider";

const CombinedProvider = ({ children }) => {
  return (
    <ProgressProvider>
      <TokenProvider>
        {children}
      </TokenProvider>
    </ProgressProvider>
  );
};

export default CombinedProvider;
