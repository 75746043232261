import { useRef, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import gsap from "gsap";
import SvgIcon from "../../../utils/svgIcon";
import { useProgress } from "../../../providers/ProgressContext";
import Modal from "../modal/modal";

const testStages = {
  face: [
    { name: "Загрузка фото", path: "/photo/face", key: "photoUploaded" },
    { name: "Анкета", path: "/survey/face", key: "photoUploaded" },
    { name: "Результаты", path: "/result/face", key: "testCompleted" },
    { name: "Рекомендации", path: "/recs/face", key: "resultsViewed" },
  ],
  // nails: [
  //   { name: "Загрузка фото", path: "/photo/nails", key: "photoUploaded" },
  //   { name: "Анкета", path: "/survey/nails", key: "photoUploaded" },
  //   { name: "Результаты", path: "/result/nails", key: "testCompleted" },
  //   { name: "Рекомендации", path: "/recs/nails", key: "resultsViewed" },
  // ],
};

const testTitles = {
  face: "Лицо",
  nails: "Ногти",
};

const DashboardBlock = ({ type }) => {
  const [openTest, setOpenTest] = useState(type);

  const [showInterruptModal, setShowInterruptModal] = useState(false);
  const [testTypeToSwitch, setTestTypeToSwitch] = useState(null);

  const { progress, resetProgress } = useProgress();
  const navigate = useNavigate();

  const asideRef = useRef(null);
  const itemsRef = useRef([]);
  const stagesRefs = useRef({});

  useEffect(() => {
    gsap.fromTo(
      asideRef.current,
      { x: "-100%", opacity: 0 },
      { x: "0%", opacity: 1, duration: 0.8, ease: "power3.out" }
    );

    gsap.fromTo(
      itemsRef.current,
      { y: 20, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.2,
        delay: 0.5,
        ease: "power3.out",
      }
    );

    if (openTest && stagesRefs.current[openTest]) {
      gsap.set(stagesRefs.current[openTest], { height: "auto", opacity: 1 });
    }
  }, [openTest]);

  const toggleTest = (newTestType) => {
    if (openTest === newTestType) {
      gsap.to(stagesRefs.current[newTestType], {
        height: 0,
        opacity: 0,
        duration: 0.3,
        ease: "power3.inOut",
        onComplete: () => setOpenTest(null),
      });
      return;
    }

    const isCurrentTestStarted = progress[openTest]?.photoUploaded;

    if (isCurrentTestStarted) {
      setShowInterruptModal(true);
      setTestTypeToSwitch(newTestType);
    } else {
      switchTest(newTestType);
    }
  };

  const switchTest = (newTestType) => {
    if (openTest && stagesRefs.current[openTest]) {
      gsap.to(stagesRefs.current[openTest], {
        height: 0,
        opacity: 0,
        duration: 0.3,
        ease: "power3.inOut",
      });
    }

    setOpenTest(newTestType);
    gsap.fromTo(
      stagesRefs.current[newTestType],
      { height: 0, opacity: 0 },
      { height: "auto", opacity: 1, duration: 0.3, ease: "power3.inOut" }
    );

    navigate(`/photo/${newTestType}`);
  };

  const handleConfirmInterrupt = () => {
    resetProgress(openTest);
    setShowInterruptModal(false);

    if (testTypeToSwitch) {
      switchTest(testTypeToSwitch);
      setTestTypeToSwitch(null);
    }
  };

  const handleCancelInterrupt = () => {
    setShowInterruptModal(false);
    setTestTypeToSwitch(null);
  };

  const isStageAccessible = (testType, key) => {
    if (!key) return true;
    return progress[testType]?.[key] || false;
  };

  return (
    <>
      <Modal
        isOpen={showInterruptModal}
        onClose={handleCancelInterrupt}
      >
        <div className="dashboard__modal">
          <h3>Хотите прервать анализ?</h3>
          <p>Вы действительно хотите прервать текущий анализ?</p>
          <span>В таком случае весь Ваш прогресс будет аннулирован.</span>
          <div className="dashboard__modal-buttons">
            <button className="btn btn--white" onClick={handleCancelInterrupt}>
              Продолжить анализ
            </button>
            <button className="btn" onClick={handleConfirmInterrupt}>
              Прервать
            </button>
          </div>
        </div>
      </Modal>

      <aside className="dashboard__content" ref={asideRef}>
        <nav>
          <ul className="dashboard__tests">
            {Object.keys(testStages).map((testType, index) => (
              <li
                key={testType}
                className={`dashboard__item ${openTest === testType ? "current" : ""
                  }`}
                ref={(el) => (itemsRef.current[index] = el)}
              >
                <button
                  className={`dashboard__toggle ${openTest === testType ? "current" : ""
                    }`}
                  onClick={() => toggleTest(testType)}
                  aria-expanded={openTest === testType}
                >
                  {testTitles[testType]}
                  <SvgIcon id="bird" width={10} height={5} />
                </button>

                <ul
                  className="dashboard__stages"
                  ref={(el) => (stagesRefs.current[testType] = el)}
                  style={{
                    overflow: "hidden",
                  }}
                >
                  {testStages[testType].map((stage, sIndex) => (
                    <li key={sIndex}>
                      <NavLink
                        to={stage.path}
                        className={({ isActive }) =>
                          `${isActive ? "active" : ""} ${isStageAccessible(testType, stage.key)
                            ? "done"
                            : "disabled"
                          }`
                        }
                        onClick={(e) => {
                          if (!isStageAccessible(testType, stage.key)) {
                            e.preventDefault();
                            alert("Завершите предыдущий этап, чтобы продолжить.");
                          }
                        }}
                      >
                        {stage.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    </>
  );
};

export default DashboardBlock;
