import { useParams } from "react-router-dom";
import ErrorScreen from "../../../error/error";
import Spinner from "../../spinner/spinner";
import { useEffect, useState } from "react";
import SvgIcon from "../../../../utils/svgIcon";

// 2nd block on page

const ResultParams = ({ resultData, error, loading, errorText }) => {
  const { type } = useParams();
  const [hasData, setHasData] = useState(false);
  const [paramsData, setParamsData] = useState([]);
  const [selectedParam, setSelectedParam] = useState(null);

  useEffect(() => {
    if (resultData) {
      setHasData(true);
      setParamsData(resultData.features);
      setSelectedParam(resultData.features[0]);
    } else {
      setHasData(false);
    }
  }, [resultData]);

  const handleParamClick = (param) => {
    setSelectedParam(param);
  };

  const Loading = loading ? <Spinner /> : null;
  const Error = error ? (
    <ErrorScreen
      errorText={errorText}
      errorLink={`${type}/result`}
      errorLinkText="Перезагрузить страницу"
    />
  ) : null;

  const content = hasData ? (
    <>
      <div className="result__params-list">
        <ul>
          {paramsData.map((param, i) => (
            <li key={i} className="result__params-item">
              <button
                className={`${selectedParam?.name === param.name ? "active" : ""
                  }`}
                onClick={() => handleParamClick(param)}
              >
                <SvgIcon id={`res_${i + 1}`} width={20} height={20} />
                <span>{param.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="result__params-bottom">
        {selectedParam && (
          <div className="result__params-details">
            <h3>{selectedParam.name}</h3>
            <p>{selectedParam.description}</p>
          </div>
        )}
        <div className="result__params-photo">
          <img src={resultData.photo_url} width={368} height={460} alt="Ваше фото." />
        </div>
      </div>
    </>
  ) : null;

  return (
    <div className="result__params">
      {Loading}
      {Error}
      {content}
    </div>
  );
};

export default ResultParams;
