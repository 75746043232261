import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Spinner from "../../spinner/spinner";
import ErrorScreen from "../../../error/error";
import SvgIcon from "../../../../utils/svgIcon";
import useSdService from "../../../../services/scandermService";

// 1st block on page
const ResultsStatus = ({ resultData, error, loading, errorText }) => {
  const { type } = useParams();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [hasData, setHasData] = useState()
  const { _transformResultStatus } = useSdService()
  const [normalData, setNormalData] = useState()

  useEffect(() => {
    if (resultData) {
      setHasData(true)
      const normalizedData = _transformResultStatus(resultData);
      setNormalData(normalizedData);
    }
  }, [resultData]);

  const handleMouseEnter = () => {
    if (window.innerWidth > 768) {
      setIsTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setIsTooltipVisible(false);
    }
  };

  const handleButtonClick = () => {
    if (window.innerWidth <= 768) {
      setIsTooltipVisible((prev) => !prev);
    }
  };

  const pluralizeYears = (count) => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    return lastTwoDigits >= 11 && lastTwoDigits <= 14
      ? `${count} лет`
      : lastDigit === 1
        ? `${count} год`
        : lastDigit >= 2 && lastDigit <= 4
          ? `${count} года`
          : `${count} лет`;
  };

  const Loading = loading ? <Spinner /> : null;
  const Error = error ? (
    <ErrorScreen
      errorText={errorText}
      errorLink={`${type}/result`}
      errorLinkText="Перезагрузить страницу"
    />
  ) : null;
  const content = hasData ? (
    <>
      <div className="result__status-text">
        <h3>{normalData.normRange.comment}</h3>
        <span>Индекс рассчитан на основе здоровья и внешнего вида кожи</span>
        <button
          type="button"
          className="result__tooltip"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleButtonClick}>
          <SvgIcon id="improve" width={16} height={16} />
          Как улучшить показатель?
        </button>
        {isTooltipVisible && (
          <p className="result__tooltip-text">
            {normalData.normRange.recomendation}
          </p>
        )}
        <div className="result__recs">
          <NavLink className="btn" to={`${type}/recs`}>
            Перейти к рекомендациям
          </NavLink>
        </div>
      </div>
      <div className="result__status-age">
        <p>Возраст кожи</p>
        <span>
          <b>{normalData.skinAge}</b> {pluralizeYears(normalData.skinAge).replace(`${normalData.skinAge} `, "")}
        </span>
      </div>
      <div className={`result__status-rank ${normalData.normRange.color}`}>
        <p>{normalData.normRange.name}</p>
        <span><b>{normalData.faceRank}</b>/10</span>
        <ul>
          {[...Array(10)].map((_, index) => (
            <li
              key={index}
              className={`${index < Math.round(normalData.faceRank) ? "filled" : ""}`}
            />
          ))}
        </ul>
      </div>
    </>
  ) : null;

  return (
    <div className={`result__status ${normalData?.normRange?.color}`}>
      {Loading}
      {Error}
      {content}
    </div>
  );
};

export default ResultsStatus;
