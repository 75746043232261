import { createContext, useState, useContext } from "react";

const ProgressContext = createContext();

export const useProgress = () => useContext(ProgressContext);

export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(() => {
    const saved = localStorage.getItem("progress");
    return saved
      ? JSON.parse(saved)
      : {
        face: {
          photoUploaded: false,
          testCompleted: false,
          resultsViewed: false,
        },
        nails: {
          photoUploaded: false,
          testCompleted: false,
          resultsViewed: false,
        },
      };
  });

  const updateProgress = (testType, newValues) => {
    setProgress((prev) => {
      const updatedTestData = {
        ...prev[testType],
        ...newValues,
      };
      const updated = {
        ...prev,
        [testType]: updatedTestData,
      };
      localStorage.setItem("progress", JSON.stringify(updated));
      return updated;
    });
  };

  const resetProgress = (testType) => {
    setProgress((prev) => {
      const updated = {
        ...prev,
        [testType]: {
          photoUploaded: false,
          testCompleted: false,
          resultsViewed: false,
        },
      };
      localStorage.setItem("progress", JSON.stringify(updated));
      return updated;
    });
  };

  return (
    <ProgressContext.Provider value={{ progress, updateProgress, resetProgress }}>
      {children}
    </ProgressContext.Provider>
  );
};
