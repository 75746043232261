import { Link } from "react-router-dom";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";
import animationData from "./404error.json";

const NotFoundPage = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <>
      <main>
        <div className="notfound">
          <div className="container">
            <div className="notfound__container">
              <h2 className="not-found-title">Страница не найдена</h2>
              <div ref={animationContainer} className="notfound__animation"></div>
              <p>К сожалению, страница, которую вы ищете, не существует или была перемещена.</p>
              <Link to="/" className="btn">Вернуться на главную</Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
