const CircularProgress = ({ current, total }) => {
  const radius = 26;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const progress = (current / total) * 100;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2} style={{ display: "block" }}>
      <circle
        stroke="#e3e3e3"
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      
      <circle
        stroke="#000"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{ transition: "stroke-dashoffset 0.35s ease" }}
        transform={`rotate(-90 ${radius} ${radius})`}
      />

      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
      >
        {current}
      </text>
    </svg>
  );
};

export default CircularProgress;
