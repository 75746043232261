import { useState } from "react";
import SvgIcon from "../../../../utils/svgIcon";
import Modal from "../../modal/modal";

const SurveyQuestion = ({
  question,
  currentAnswer,
  currentStep,
  onRadioSelect,
  onCheckboxChange,
  onAnswerChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const handleModalOpen = (text, title) => {
    setModalContent(text || "Информация отсутствует");
    setModalTitle(title || "Подсказка");
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent("");
    setModalTitle("");
  };

  if (!question) {
    return null;
  }

  return (
    <>
      <h2>{question.text}</h2>
      {question.description && <p>{question.description}</p>}
      <ul className="survey__answers">
        {question.type === "radio" &&
          question.answers.map((answer) => {
            const isChecked = currentAnswer?.answer_id === answer.answer_id;

            return (
              <li className="survey__radio" key={answer.answer_id}>
                <button
                  type="button"
                  onClick={() => handleModalOpen(answer.hintText, answer.hintTitle)}
                >
                  <SvgIcon id="tip" width={14} height={14} />
                  {answer.text}
                </button>
                <label>
                  <input
                    type="radio"
                    name={`question-${currentStep}`}
                    checked={isChecked}
                    onChange={() =>
                      onRadioSelect(question.question_id, answer.answer_id)
                    }
                  />
                  <span className="survey__radio"></span>
                </label>
              </li>
            );
          })}

        {question.type === "checkbox" &&
          question.answers.map((answer) => {
            const checked = currentAnswer?.answer_id?.includes(answer.answer_id);
            return (
              <li className="survey__check" key={answer.answer_id}>
                <button
                  type="button"
                  onClick={() => handleModalOpen(answer.hintText, answer.hintTitle)}
                >
                  <SvgIcon id="tip" width={14} height={14} />
                  {answer.text}
                </button>
                <label>
                  <input
                    type="checkbox"
                    checked={checked || false}
                    onChange={(e) =>
                      onCheckboxChange(
                        question.question_id,
                        answer.answer_id,
                        e.target.checked
                      )
                    }
                  />
                  <span className="survey__check">
                    <SvgIcon id="check" width={16} height={16} />
                  </span>
                </label>
              </li>
            );
          })}

        {question.type === "number" && (
          <li className="survey__number">
            <label>
              <input
                type="number"
                value={currentAnswer?.answer_id ?? ""}
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value.slice(0, 2);
                  onAnswerChange(question.question_id, e.target.value);
                }}
                placeholder="Сколько Вам лет"
              />
            </label>
          </li>
        )}
      </ul>

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <div className="survey__modal">
          <h3>{modalTitle}</h3>
          <p>{modalContent}</p>
          <button className="btn" type="button" onClick={handleModalClose}>Понятно</button>
        </div>
      </Modal>
    </>
  );
};

export default SurveyQuestion;
