import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import "./styles/styles.scss";
import CombinedProvider from './providers/CombinedProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <CombinedProvider>
      <App />
    </CombinedProvider>
  // </React.StrictMode>
);

reportWebVitals();
